/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/jsx-no-useless-fragment */
import { Button, Checkbox, Input, List, Space, Tooltip, Typography } from "antd";
import { FontAwesomeIcon, Icon, SvgIcon } from "assets/icons";
import CustomTable from "components/CustomTable";
import { useGetProducts } from "hooks/product/product";
import { useWarehouse } from "hooks/warehouse";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
// import "styles/custom_component.scss";
import { buildQueryString, debounce, formatCurrency, getQuery, removeWhiteSpace } from "utils/helperFuncs";
import {
  FilterAttributes,
  FilterExportVAT,
  FilterInstockVat,
  FilterLevelPrice,
  FilterManufacture,
  FilterMOQ,
  FilterPricePolicy,
  FilterRealStocks,
  FilterStatus,
  FilterStocksSupplier,
  FilterVatPercent,
  FilterVatValue,
} from "./components/FilterProduct";
// import "./index.scss";
import { HistoryOutlined } from "@ant-design/icons";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";
import LoadingComponent from "components/LoadingComponent";
import PageHeader from "components/PageHeader";
import { PRODUCT_INPUT_TYPE } from "config/constants";
import RadioGroup from "features/Warranty/WarrantyList/component/RadioGroup";
import { useGetProdAttribute } from "hooks/attribute";
import { useCartPermissions } from "hooks/cart";
import { useProductPermissions } from "hooks/product/product";
import { useGetRegionsList } from "hooks/regions";
import { isEqual } from "lodash";
import CustomModalChangeSeller from "pages/App/Cart/List/components/CustomModalChangeSeller";
import { convertUnit, SELLING_STATUS, VAT_TYPE } from "../../constants";
import BatchTools from "./components/BatchTools";
import CategoryTreeFilter from "./components/CategoryTreeFilter";
import CustomModalDownloadFileUpdatePrice from "./components/CustomModalDownloadFileUpdatePrice";
import CustomModalImportPriceSeries from "./components/CustomModalImportPriceSeries";
import CustomModalRecommendProductName from "./components/CustomModalRecommendProductName";
import DrawerEditProduct from "./components/DrawerEditProduct";
import EditableCell from "./components/EditableCell";
import TagsList from "./components/TagsList";

import EllipsisText from "components/_storybooks/EllipsisText";
import SpaceCustom from "components/_storybooks/SpaceCustom";
import TextAlignLeft from "components/TextAlignLeft";
import PopoverPromotion from "./components/PopoverPromotion";
import PriceDrop from "./components/PriceDrop";
import SelectStatus from "./components/SelectStatus";

const ProductList = () => {
  const history = useHistory();
  const GET_QUERY = getQuery();

  const { t } = useTranslation();
  const [params, setParams] = useState({
    filters: {
      query: GET_QUERY.query || undefined,
      categoryIDs: GET_QUERY.categoryIDs || undefined,
      manufacturerIDs: GET_QUERY.manufacturerIDs || undefined,
      priceLevel1: GET_QUERY.priceLevel1 ? JSON.parse(GET_QUERY.priceLevel1) : undefined,
      priceLevel2: GET_QUERY.priceLevel2 ? JSON.parse(GET_QUERY.priceLevel2) : undefined,
      priceLevel3: GET_QUERY.priceLevel3 ? JSON.parse(GET_QUERY.priceLevel3) : undefined,
      price: GET_QUERY.price ? JSON.parse(GET_QUERY.price) : undefined,
      realStocks: GET_QUERY.realStocks ? GET_QUERY.realStocks?.map((item) => JSON.parse(item)) : undefined,
      vat: GET_QUERY.vat,
      vatPercent: GET_QUERY.vatPercent ? JSON.parse(GET_QUERY.vatPercent) : undefined,
      realStockHasVat: GET_QUERY.realStockHasVat ? JSON.parse(GET_QUERY.realStockHasVat) : undefined,
      attributeValues: GET_QUERY.attributeValues
        ? GET_QUERY.attributeValues?.map((item) => JSON.parse(item))
        : undefined,
      pricePolicyType: GET_QUERY?.pricePolicyType || undefined,
      sellingStatuses: GET_QUERY?.sellingStatuses || undefined,
      supplierStocks: GET_QUERY.supplierStocks ? GET_QUERY.supplierStocks?.map((item) => JSON.parse(item)) : undefined,
      vatTypes: GET_QUERY.vatTypes || undefined,
      vatValue: GET_QUERY.vatValue || undefined,
      minOrderQuantity: GET_QUERY.minOrderQuantity ? JSON.parse(GET_QUERY.minOrderQuantity) : undefined,
    },
    pagination: {
      limit: GET_QUERY.limit || 10,
      offset: GET_QUERY.offset || 0,
    },
  });
  const [selectionType] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const [openDrawerEditProduct, setOpenDrawerEditProduct] = useState(false);
  // const [currentActive, setCurrentActive] = useState("all");
  const [dataEditProductSelected, setDataEditProductSelected] = useState(null);
  const [isAvailable, setIsAvailable] = useState(null);
  const { canViewProduct, canUpdate, loadingProductPermissions, canUpdatePrice } = useProductPermissions();
  const { cartPermissions } = useCartPermissions();

  const countSelectedRowKeys = selectedRowKeys?.length ? selectedRowKeys?.length : 0;

  const filterItemsTypeOptions = [
    { label: t("common.all"), value: false },
    { label: `${t("cart.selected")} (${countSelectedRowKeys})`, value: true },
  ];

  const [isFilterSelected, setIsFilterSelected] = useState(false);

  const handleChangeTabSelect = (e) => setIsFilterSelected(e.target.value);

  const { loading, data: productData, total: totalProduct, refetch } = useGetProducts(params, "cache-first");
  const { loading: loadProdAtt, data: prodAtt } = useGetProdAttribute(params.filters.categoryIDs);

  const { warehouses } = useWarehouse();
  const { regions } = useGetRegionsList();

  const handleOpenDrawerEditProduct = (record, AVAILABLE) => {
    setOpenDrawerEditProduct(true);
    setIsAvailable(AVAILABLE);
    setDataEditProductSelected(record);
  };

  const handleCancelDrawerEditProduct = () => {
    setOpenDrawerEditProduct(false);
    setDataEditProductSelected(null);
    setIsAvailable(null);
  };

  const handleSetSelectedRows = (value) => {
    setSelectedRows(value);
  };
  const handleSetSelectedRowKeys = (value) => {
    setSelectedRowKeys(value);
  };

  useEffect(() => {
    // check change data, re-fill selectedRows
    const hasMatchingItem = productData.some((itemProduct) => {
      return selectedRows.some((itemSelectedRow) => itemSelectedRow?.id === itemProduct?.id);
    });

    if (hasMatchingItem) {
      const newSelectedRows = productData.filter((itemProduct) => {
        return selectedRows.some((itemSelectedRow) => itemSelectedRow?.id === itemProduct?.id);
      });
      setSelectedRows(newSelectedRows);
    }
  }, [productData]);

  // rowSelection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record) => {
      return {
        disabled: record.sellingStatus === SELLING_STATUS.UNAVAILABLE,
        name: record.name,
      };
    },
    selectedRowKeys: selectedRowKeys,
    preserveSelectedRowKeys: true,
    renderCell: (checked, record, index, originNode) => {
      return (
        <>
          <Checkbox {...originNode.props} />
          {canUpdatePrice && (
            <Tooltip title={t("product.edit")}>
              <SvgIcon.EditIcon
                style={{
                  cursor: "pointer",
                }}
                onClick={() => handleOpenDrawerEditProduct(record)}
              />
            </Tooltip>
          )}
        </>
      );
    },
  };

  function filterProductExtra(values) {
    const newParams = {
      ...params,
      filters: {
        ...params.filters,
        ...values,
      },
      pagination: {
        ...params.pagination,
        offset: 0,
      },
    };

    setParams(newParams);
  }

  function filterProductPrices(values, key) {
    const newValue = values?.isSpecified === undefined ? null : values;

    const newParams = {
      ...params,
      filters: {
        ...params.filters,
        [`${key}`]: newValue,
      },
      pagination: {
        ...params.pagination,
        offset: 0,
      },
    };

    setParams(newParams);
  }

  function filterRealStocks(values) {
    //first make it unique array then filter it has own property range
    const realStocks = [
      ...new Map(
        [...(params.filters?.realStocks ? params.filters.realStocks : []), values].map((item) => [
          item.warehouseID,
          item,
        ])
      ).values(),
    ].filter((item) => item.hasOwnProperty("range") && item.range);

    setParams({
      ...params,
      filters: {
        ...params.filters,
        realStocks: realStocks.length > 0 ? realStocks : undefined,
      },
      pagination: {
        ...params.pagination,
        offset: 0,
      },
    });
  }

  function supplierStocksFilter(values) {
    const supplierStocks = [
      ...new Map(
        [...(params.filters.supplierStocks ? params.filters.supplierStocks : []), values].map((item) => {
          const { isRealStock, isRealStockVAT, ...rest } = item;
          return [item?.regionID, rest];
        })
      ).values(),
    ].filter((item) => item.hasOwnProperty("rangeRealStock") && item.rangeRealStock);

    setParams({
      ...params,
      filters: {
        ...params.filters,
        supplierStocks: supplierStocks.length > 0 ? supplierStocks : undefined,
      },
      pagination: {
        ...params.pagination,
        offset: 0,
      },
    });
  }

  const filterProductAttributes = (values) => {
    const attributeValues = [
      ...new Map(
        [...(params.filters?.attributeValues ? params.filters.attributeValues : []), values].map((item) => [
          item.attributeID,
          item,
        ])
      ).values(),
    ].filter((item) => item.valueIDs?.length > 0);

    setParams({
      ...params,
      filters: {
        ...params.filters,
        attributeValues: attributeValues.length > 0 ? attributeValues : undefined,
      },
      pagination: {
        ...params.pagination,
        offset: 0,
      },
    });
  };

  const renderChildrenInStock = (warehouses) => {
    const findDefaultValue = (realStocks, value) => {
      if (realStocks && value) {
        return realStocks.find((item) => item.warehouseID === value);
      } else return undefined;
    };

    return warehouses?.map((warehouse, index) => ({
      title: warehouse.name,
      key: index,
      align: "center",
      editable: "false",
      render: (_, record) =>
        record?.stocks?.filter((stock) => stock?.warehouse?.id === warehouse?.id)[0]?.quantity || "--",
      filterDropdown: (
        <FilterRealStocks
          onFilter={filterRealStocks}
          warehouseID={warehouse.id}
          defaultValue={findDefaultValue(params.filters.realStocks, warehouse.id)}
        />
      ),
    }));
  };

  const renderChildrenInStockSupplier = (regions) => {
    return regions?.map((region, index) => ({
      title: region?.name,
      key: index,
      align: "center",
      editable: false,
      render: (_, record) => {
        const stockSupplier = record?.stocksSupplier?.find((ss) => ss?.regionID === region?.id);
        if (stockSupplier) {
          return (
            <span>
              <span style={{ color: "#2246DC", fontWeight: "bold" }}>{stockSupplier?.totalQuantity}</span>/
              {stockSupplier?.vatQuantity}
            </span>
          );
        } else {
          return "--";
        }
      },
      filterDropdown: <FilterStocksSupplier onFilter={supplierStocksFilter} regionID={region?.id} />,
    }));
  };

  const renderProductAttributes = (attributes = []) => {
    const findDefaultValue = (attributes, value) => {
      if (attributes && value) {
        return attributes.find((item) => item.attributeID === value);
      } else return undefined;
    };

    if (attributes?.length > 0)
      return attributes?.map((attribute) => ({
        title: attribute.name,
        key: attribute.id,
        align: "center",
        editable: "false",
        filterDropdown: (
          <FilterAttributes
            attributeId={attribute.id}
            attributeList={attribute.values}
            defaultValue={findDefaultValue(params.filters.attributeValues, attribute.id)?.valueIDs}
            onFilter={filterProductAttributes}
          />
        ),
        render: (_, record) => {
          const prodAtt = record?.fullAttributeValues?.find((item) => item.attributeID === attribute.id);
          if (prodAtt) {
            return (
              <span>{prodAtt?.attributeValues?.map((attributeValue) => attributeValue.value).join(", ") || "--"}</span>
            );
          }
          return "--";
        },
      }));
    return [];
  };

  const columns = [
    // Thông tin sản phẩm
    {
      width: 450,
      title: "Thông tin sản phẩm",
      fixed: "left",
      render: (_, record) => {
        return (
          <SpaceCustom>
            <Link target="_blank" to={`product/detail/${record.id}`}>
              <EllipsisText
                isLink
                copyable={{
                  text: record?.name,
                }}
                line={1}
                strong
                title={record?.code}
              />
            </Link>
            <EllipsisText line={3} title={record?.name} />
          </SpaceCustom>
        );
      },
    },
    //Giá chưa vat
    {
      title: "GIÁ CHƯA VAT",
      dataIndex: ["productPrices", "price"],
      align: "right",
      render: (_, record) => {
        return <div>{formatCurrency(record?.productPrices?.price)}</div>;
      },
      editable: "true",
      inputType: PRODUCT_INPUT_TYPE.CURRENCY,
      filterDropdown: (
        <FilterLevelPrice
          onFilter={(priceLevel) => {
            filterProductPrices(priceLevel, "price");
          }}
          defaultValue={params?.filters?.price}
        />
      ),
    },
    //Xuất vat
    {
      title: "XUẤT VAT",
      dataIndex: "vatType",
      align: "center",
      render: (_, record) => {
        switch (record.vatType) {
          case VAT_TYPE.REQUIRED:
            return <SvgIcon.SuccessIcon />;
          case VAT_TYPE.NOT_REQUIRED:
            return <SvgIcon.CloseIcon />;
          case VAT_TYPE.OPTIONAL:
            return <span style={{ color: "#052987" }}>{t("v2.product.drawer_edit_product.optional")}</span>;
        }
      },
      filterDropdown: <FilterExportVAT onFilter={filterProductExtra} defaultValue={params.filters.vatTypes} />,
    },
    //% VAT VALUE
    {
      title: t("product.tableTitle.vatValue"),
      dataIndex: "vatValue",
      render: (_, record) => <div>{record?.vatValue >= 0 ? formatCurrency(record?.vatValue) : "KCT"}</div>,
      align: "right",
      editable: "true",
      inputType: PRODUCT_INPUT_TYPE.VAT_VALUE,
      filterDropdown: <FilterVatValue onFilter={filterProductExtra} defaultValue={params.filters.vatValue} />,
    },
    //% VAT
    {
      title: "LOẠI % VAT",
      dataIndex: "vatPercent",
      align: "center",
      render: (_, record) => {
        return <>{record.vatPercent >= 0 ? `${record.vatPercent}%` : "KCT"}</>;
      },
      editable: "true",
      inputType: PRODUCT_INPUT_TYPE.VAT_PERCENT,
      filterDropdown: <FilterVatPercent onFilter={filterProductExtra} defaultValue={params.filters.vatPercent} />,
    },
    //Giá chưa vat
    {
      title: "GIÁ CÓ VAT",
      dataIndex: ["productPrices", "priceVat"],
      align: "right",
      render: (_, record) => <div>{formatCurrency(record?.productPrices?.priceVat)}</div>,
      editable: "true",
      inputType: PRODUCT_INPUT_TYPE.CURRENCY,
      filterDropdown: (
        <FilterLevelPrice
          onFilter={(priceLevel) => {
            filterProductPrices(priceLevel, "priceVat");
          }}
          defaultValue={params?.filters?.priceVat}
        />
      ),
    },
    // PRICE POLICY
    {
      title: t("product.tableTitle.pricePolicy"),
      filterDropdown: (
        <FilterPricePolicy onFilter={filterProductExtra} defaultValue={params?.filters?.pricePolicyType} />
      ),
      align: "center",
      render: (_, record) => {
        return record?.productDiscounts?.length ? (
          <TextAlignLeft
            value={
              <List
                dataSource={record?.productDiscounts}
                size="small"
                renderItem={(item) => (
                  <List.Item style={{ border: "none", padding: "4px 16px" }}>
                    {item?.maxQuantity ? (
                      <Typography.Text>
                        {t("product.pricePolicy.descriptionHasMaxQuantity", {
                          minQuantity: item.minQuantity,
                          maxQuantity: item.maxQuantity,
                          discountAmount: formatCurrency(item.discountAmount),
                        })}
                      </Typography.Text>
                    ) : (
                      <Typography.Text>
                        {t("product.pricePolicy.descriptionNotHasMaxQuantity", {
                          minQuantity: item.minQuantity,
                          maxQuantity: item.maxQuantity,
                          discountAmount: formatCurrency(item.discountAmount),
                        })}
                      </Typography.Text>
                    )}
                  </List.Item>
                )}
              />
            }
          />
        ) : (
          "--"
        );
      },
    },
    //Tồn kho
    {
      title: t("product.tableTitle.inStock"),
      children: renderChildrenInStock(warehouses),
    },
    //Tồn VAT
    {
      title: <span>{t("product.tableTitle.inStockVat")}</span>,
      align: "center",
      render: (_, record) => {
        return (record?.stocks && record?.stocks[0].quantityRealStockHasVat) ?? 0;
      },
      filterDropdown: <FilterInstockVat onFilter={filterProductExtra} defaultValue={params.filters.realStockHasVat} />,
    },
    //Tồn khả dụng/ tồn vat của ncc
    {
      title: "Tồn khả dụng/ tồn vat của ncc",
      children: renderChildrenInStockSupplier(regions),
    },
    // MOQ
    {
      title: "Mức MOQ",
      dataIndex: "minOrderQuantity",
      align: "center",
      render: (_, record) => {
        return record?.minOrderQuantity;
      },
      filterDropdown: <FilterMOQ onFilter={filterProductExtra} defaultValue={params.filters.minOrderQuantity} />,
    },
    //Bảo hành
    {
      title: t("product.tableTitle.warranty"),
      dataIndex: "warranty",
      align: "center",
      editable: "false",
      render: (_, record) => (
        <div>{record?.warrantyUnit ? `${record.warranty} ${convertUnit(record?.warrantyUnit)}` : "--"}</div>
      ),
    },
    //Thương hiệu
    {
      title: t("product.tableTitle.brand"),
      dataIndex: ["manufacturer", "name"],
      align: "center",
      editable: "false",
      render: (_, record) => <div>{record?.manufacturer?.name ? record.manufacturer.name : "--"}</div>,
      filterDropdown: <FilterManufacture onFilter={filterProductExtra} defaultValue={params.filters.manufacturerIDs} />,
    },
    //Thuộc tính của sản phẩm
    ...renderProductAttributes(prodAtt),
    // PROMOTION
    {
      title: <span style={{ textTransform: "uppercase" }}>{t("product.tableTitle.promotions")}</span>,
      // filterDropdown: <FilterPromotions />,
      render: (_, record) => (
        <PopoverPromotion
          productCode={record.code}
          conditionPromotionProductsOnGoing={record.conditionPromotionProductsOnGoing}
        />
      ),
    },

    // NOTE
    {
      title: t("product.tableTitle.note"),
      dataIndex: "note",
      render: (_, record) => <CustomTextHiddenLine line={2} text={record?.note} />,
      align: "left",
      inputType: PRODUCT_INPUT_TYPE.NOTE,
      editable: "true",
    },
    ...(canUpdate
      ? [
          //Trạng thái
          {
            title: t("product.tableTitle.status"),
            dataIndex: "isActive",
            align: "center",
            editable: "false",
            fixed: "right",
            filterDropdown: (
              <FilterStatus onFilter={filterProductExtra} defaultValue={params?.filters?.sellingStatuses} />
            ),
            render: (_, record) => {
              return (
                <div>
                  <SelectStatus
                    sellingStatus={record?.sellingStatus}
                    id={record?.id}
                    record={record}
                    handleOpenDrawerEditProduct={handleOpenDrawerEditProduct}
                  />
                  <div
                    style={{
                      color: "#2246DC",
                      marginTop: 5,
                      fontSize: "20px",
                    }}
                  >
                    <Link to={`/product-price-log-detail/${record.id}`}>
                      <HistoryOutlined />
                    </Link>
                  </div>
                </div>
              );
            },
          },
        ]
      : []),
  ];

  function filterUserByText([text]) {
    const searchText = removeWhiteSpace(text);

    setParams((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        query: searchText.length > 0 ? searchText : undefined,
      },
      pagination: {
        ...prev.pagination,
        offset: 0,
      },
    }));
  }

  //Filter user by text
  const filterProductByTextDebounced = debounce(filterUserByText, 500);

  //Using recursion
  const mapColumns = (col) => {
    if (col.editable === "false") {
      return col;
    }

    const newCol = {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.inputType,
        editable: col.editable,
        dataIndex: col.dataIndex,
      }),
    };
    if (col.children) {
      newCol.children = col.children.map(mapColumns);
    }
    return newCol;
  };

  const mergedColumns = columns.map(mapColumns);

  function filterProductByCategoryId(ids) {
    if (isEqual(ids, params.filters.categoryIDs)) {
      return;
    }
    let newParams = {
      ...params,
      filters: {
        ...params.filters,
        categoryIDs: ids?.length > 0 ? ids : undefined,
      },
    };
    setParams(newParams);
  }

  function onTableChange(pagination, filters, sorter) {
    if (isFilterSelected) return;
    const { current, pageSize } = pagination;

    let newParams = {
      ...params,
      pagination: { ...params.pagination, offset: (current - 1) * pageSize },
    };
    setParams(newParams);
  }

  useEffect(() => {
    //build query string
    const paramsFilter = params.filters;
    const paramsPagination = params.pagination;
    const queryString = {
      query: paramsFilter.query ? `${paramsFilter.query}` : undefined,
      categoryIDs: paramsFilter.categoryIDs || undefined,
      manufacturerIDs: paramsFilter.manufacturerIDs || undefined,
      priceLevel1: paramsFilter.priceLevel1 !== undefined ? JSON.stringify(paramsFilter.priceLevel1) : undefined,
      priceLevel2: paramsFilter.priceLevel2 !== undefined ? JSON.stringify(paramsFilter.priceLevel2) : undefined,
      priceLevel3: paramsFilter.priceLevel3 !== undefined ? JSON.stringify(paramsFilter.priceLevel3) : undefined,
      price: paramsFilter.price !== undefined ? JSON.stringify(paramsFilter.price) : undefined,
      realStocks: paramsFilter.realStocks ? paramsFilter.realStocks?.map((item) => JSON.stringify(item)) : undefined,
      minOrderQuantity:
        paramsFilter.minOrderQuantity !== undefined ? JSON.stringify(paramsFilter.minOrderQuantity) : undefined,
      vat: paramsFilter.vat,
      vatPercent: paramsFilter.vatPercent ? JSON.stringify(paramsFilter.vatPercent) : undefined,
      realStockHasVat: paramsFilter.realStockHasVat ? JSON.stringify(paramsFilter.realStockHasVat) : undefined,
      pricePolicyType: paramsFilter.pricePolicyType,
      attributeValues: paramsFilter.attributeValues
        ? paramsFilter.attributeValues?.map((item) => JSON.stringify(item))
        : undefined,
      supplierStocks: paramsFilter.supplierStocks
        ? paramsFilter.supplierStocks?.map((item) => JSON.stringify(item))
        : undefined,
      offset: paramsPagination?.offset || 0,
      limit: paramsPagination?.limit || 10,
      vatTypes: paramsFilter.vatTypes,
      vatValue: paramsFilter.vatValue,
    };

    buildQueryString({ params: { ...queryString } });
  }, [JSON.stringify(params)]);

  return (
    <LoadingComponent loading={loadingProductPermissions}>
      {canViewProduct && (
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <PageHeader pageTitle="Danh sách sản phẩm" />
          <div
            style={{
              backgroundColor: "#e9eef5",
              borderRadius: "8px",
              padding: "8px",
              width: "100%",
              justifyContent: "space-between",
              flexWrap: "wrap",
              display: "flex",
              gap: "8px",
            }}
          >
            <div style={{ zIndex: 1 }}>
              <Space style={{ width: "100%", flexWrap: "wrap" }}>
                <div style={{ width: "300px" }}>
                  <CategoryTreeFilter onFilter={filterProductByCategoryId} defaultValue={params.filters.categoryIDs} />
                </div>
                <Input
                  prefix={<FontAwesomeIcon icon={Icon.faSearch} />}
                  placeholder={t("product.searchPlaceholder")}
                  onChange={(e) => {
                    filterProductByTextDebounced(e.target.value);
                  }}
                  allowClear={true}
                  defaultValue={params.filters.query}
                  style={{ width: "350px" }}
                />
              </Space>
            </div>
            <div style={{ zIndex: 2 }}>
              <Space style={{ width: "100%", justifyContent: "end", flexWrap: "wrap" }}>
                {canUpdatePrice && <CustomModalImportPriceSeries />}
                {canUpdatePrice && (
                  <CustomModalDownloadFileUpdatePrice params={params} selectedRowKeys={selectedRowKeys} />
                )}
                <BatchTools
                  selectedRowKeys={selectedRowKeys}
                  selectedRows={selectedRows}
                  handleSetSelectedRows={handleSetSelectedRows}
                  handleSetSelectedRowKeys={handleSetSelectedRowKeys}
                />
                <Button
                  style={{ color: "#2246dc", borderColor: "#2246dc" }}
                  onClick={() => history.push("/product-price-logs")}
                >
                  <HistoryOutlined />
                  {t("common.historyUpdatePriceProduct")}
                </Button>
                {<CustomModalRecommendProductName />}
                {cartPermissions.canCreateCart && (
                  <CustomModalChangeSeller
                    action="addCartFromProduct"
                    selectedProducts={selectedRowKeys}
                    selectedRows={selectedRows}
                  />
                )}
              </Space>
            </div>
          </div>
          <PriceDrop />
          <TagsList params={params} setParams={setParams} refetch={refetch} />
          <RadioGroup filterItemsTypeOptions={filterItemsTypeOptions} handleChange={handleChangeTabSelect} />
          <CustomTable
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            rowSelection={{
              type: selectionType,
              ...rowSelection,
            }}
            rowKey={(record) => record?.id}
            loading={loading || loadProdAtt}
            columns={mergedColumns}
            dataSource={isFilterSelected ? selectedRows : productData}
            scroll={{ x: "max-content" }}
            pagination={
              !isFilterSelected && {
                total: totalProduct,
                pageSize: params?.pagination?.limit,
                current: params?.pagination?.offset / params?.pagination?.limit + 1 || 1,
                showSizeChanger: false,
              }
            }
            onChange={onTableChange}
          />
          <DrawerEditProduct
            dataSelected={dataEditProductSelected}
            handleCancelDrawerEditProduct={handleCancelDrawerEditProduct}
            openDrawerEditProduct={openDrawerEditProduct}
            isAvailable={isAvailable}
          />
        </div>
      )}
    </LoadingComponent>
  );
};

export default ProductList;
